export const menuItems = [
    {
        id: 100,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 200,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 300,
        label: 'menuitems.machine.text',
        icon: ' ri-fridge-line',
        subItems: [
            {
                id: 301,
                label: 'menuitems.machine.text',
                link: '/machine',
            },
            {
                id: 302,
                label: 'menuitems.machine.type.text',
                link: '/machine_type'
            },
            {
                id: 303,
                label: 'menuitems.site.text',
                icon: 'ri-map-pin-line',
                link: '/site'
            }
        ]
    },
    {
        id: 400,
        label: 'menuitems.advertisement.text',
        icon: 'ri-advertisement-line',
        subItems: [
            {
                id: 401,
                label: 'menuitems.advertisement.text',
                link: '/advertisement'
            },
            {
                id: 402,
                label: 'menuitems.advertisement.type.text',
                link: '/advertisement_type'
            },
        ]

    },
    {
        id: 500,
        label: 'menuitems.product.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 501,
                label: 'menuitems.product.text',
                link: '/product'
            },
            {
                id: 502,
                label: 'menuitems.product.type.text',
                link: '/product_type'
            }
        ]
    },
    {
        id: 600,
        label: 'menuitems.cashbox.text',
        icon: 'ri-money-dollar-box-line',
        // link: '/cashbox',
        subItems: [
            {
                id: 601,
                label: 'menuitems.cashbox.text',
                link: '/cashbox'
            },
            {
                id: 602,
                label: 'menuitems.cashbox.surplus.text',
                link: '/surplus'
            }
        ]
    },
    {
        id: 700,
        label: 'menuitems.inventory.text',
        icon: 'ri-luggage-cart-line',
        subItems: [
            {
                id: 701,
                label: 'menuitems.inventory.text',
                link: '/inventory'
            },
            {
                id: 702,
                label: 'menuitems.inventory.issue.text',
                link: '/inventory/issue'
            },
            {
                id: 703,
                label: 'menuitems.movement.text',
                link: '/movement'
            },
        ]
    },
    {
        id: 800,
        label: 'menuitems.outlet.text',
        icon: 'ri-bank-card-line',
        link: '/outlet'
    },
    {
        id: 900,
        label: 'menuitems.principle.text',
        icon: 'ri-honour-line',
        link: '/principle'
    },
    {
        id: 1000,
        label: 'menuitems.transactions.text',
        icon: 'ri-money-dollar-circle-line',
        link: '/transactions'
    },
    {
        id: 1100,
        label: 'menuitems.warehouse.text',
        icon: 'ri-home-8-line',
        subItems: [
            {
                id: 1101,
                label: 'menuitems.warehouse.text',
                link: '/warehouse'
            },
            {
                id: 1102,
                label: 'menuitems.warehouse.region.text',
                link: '/region'
            },
        ]
    },
    {
        id: 1200,
        label: 'menuitems.work.text',
        icon: 'ri-drag-move-line',
        subItems: [
            {
                id: 1201,
                label: 'menuitems.vehicle.text',
                icon: 'ri-truck-line',
                link: '/vehicle'
            },
            {
                id: 1202,
                label: 'menuitems.route.text',
                icon: 'ri-route-line',
                link: '/route'
            },
            {
                id: 1203,
                label: 'menuitems.work.text',
                icon: 'ri-suitcase-line',
                link: '/work'
            },
        ]
    },
    {
        id: 1400,
        label: 'menuitems.report.text',
        icon: 'ri-file-chart-line',
        // link: '/report',
        subItems: [
            {
                id: 1401,
                label: 'menuitems.report.text',
                link: '/report'
            },
            {
                id: 1401,
                label: 'menuitems.report.monthly.text',
                link: '/report/monthly'
            },
            {
                id: 1401,
                label: 'menuitems.report.yearly.text',
                link: '/report/yearly'
            },
        ]
    },
    {
        id: 1500,
        label: 'menuitems.purchasing.text',
        icon: 'ri-shopping-cart-line',
        subItems: [
            {
                id: 1501,
                label: 'menuitems.supplier.text',
                icon: 'ri-building-2-line',
                link: '/supplier'
            },
            // {
            //     id: 1502,
            //     label: 'menuitems.purchase.text',
            //     icon: 'ri-shopping-cart-line',
            //     link: '/purchase'
            // },
            {
                id: 1503,
                label: 'menuitems.receive.text',
                icon: 'ri-shopping-cart-line',
                link: '/receive'
            }
        ]
    },
    {
        id: 1600,
        label: 'menuitems.user.text',
        icon: 'ri-user-line',
        subItems: [
            {
                id: 1601,
                label: 'menuitems.user.text',
                icon: 'ri-user-line',
                link: '/user'
            },
            {
                id: 1602,
                label: 'menuitems.user.role.text',
                icon: 'ri-shield-user-line',
                link: '/user/role'
            }
        ]
    },
    {
        id: 1700,
        label: 'menuitems.role.text',
        icon: 'ri-shield-user-line',
        subItems: [
            {
                id: 1701,
                label: 'menuitems.role.text',
                icon: 'ri-shield-user-line',
                link: '/role'
            },
            {
                id: 1702,
                label: 'menuitems.role.permission.text',
                icon: 'ri-shield-line',
                link: '/permission'
            }
        ]
    }
    // {
    //     id: 14,
    //     label: 'menuitems.email.text',
    //     icon: 'ri-mail-send-line',
    //     subItems: [
    //         {
    //             id: 15,
    //             label: 'menuitems.email.list.inbox',
    //             link: ''
    //         },
    //         {
    //             id: 16,
    //             label: 'menuitems.email.list.reademail',
    //             link: ''
    //         }
    //     ]
    // },
    // {
    //     id: 17,
    //     label: 'menuitems.kanban.text',
    //     icon: 'ri-artboard-2-line',
    //     link: ''
    // },
    // {
    //     id: 18,
    //     isLayout: true
    // },
    // {
    //     id: 19,
    //     label: 'menuitems.pages.text',
    //     isTitle: true
    // },
    // {
    //     id: 20,
    //     label: 'menuitems.authentication.text',
    //     icon: 'ri-account-circle-line',
    //     subItems: [
    //         {
    //             id: 21,
    //             label: 'menuitems.authentication.list.login',
    //             link: ''
    //         },
    //         {
    //             id: 22,
    //             label: 'menuitems.authentication.list.register',
    //             link: ''
    //         },
    //         {
    //             id: 23,
    //             label: 'menuitems.authentication.list.recoverpwd',
    //             link: ''
    //         },
    //         {
    //             id: 24,
    //             label: 'menuitems.authentication.list.lockscreen',
    //             link: ''
    //         }
    //     ]
    // },
    // {
    //     id: 25,
    //     label: 'menuitems.utility.text',
    //     icon: 'ri-profile-line',
    //     subItems: [
    //         {
    //             id: 26,
    //             label: 'menuitems.utility.list.starter',
    //             link: ''
    //         },
    //         {
    //             id: 27,
    //             label: 'menuitems.utility.list.maintenance',
    //             link: ''
    //         },
    //         {
    //             id: 28,
    //             label: 'menuitems.utility.list.comingsoon',
    //             link: ''
    //         },
    //         {
    //             id: 29,
    //             label: 'menuitems.utility.list.timeline',
    //             link: ''
    //         },
    //         {
    //             id: 30,
    //             label: 'menuitems.utility.list.faqs',
    //             link: ''
    //         },
    //         {
    //             id: 31,
    //             label: 'menuitems.utility.list.pricing',
    //             link: ''
    //         },
    //         {
    //             id: 32,
    //             label: 'menuitems.utility.list.error404',
    //             link: ''
    //         },
    //         {
    //             id: 33,
    //             label: 'menuitems.utility.list.error500',
    //             link: ''
    //         },
    //     ]
    // },
    // {
    //     id: 36,
    //     label: 'menuitems.components.text',
    //     isTitle: true
    // },
    // {
    //     id: 37,
    //     label: 'menuitems.uielements.text',
    //     icon: 'ri-pencil-ruler-2-line',
    //     subItems: [
    //         {
    //             id: 38,
    //             label: 'menuitems.uielements.list.alerts',
    //             link: ''
    //         },
    //         {
    //             id: 39,
    //             label: 'menuitems.uielements.list.buttons',
    //             link: ''
    //         },
    //         {
    //             id: 40,
    //             label: 'menuitems.uielements.list.cards',
    //             link: ''
    //         },
    //         {
    //             id: 41,
    //             label: 'menuitems.uielements.list.carousel',
    //             link: ''
    //         },
    //         {
    //             id: 42,
    //             label: 'menuitems.uielements.list.dropdowns',
    //             link: ''
    //         },
    //         {
    //             id: 43,
    //             label: 'menuitems.uielements.list.grid',
    //             link: ''
    //         },
    //         {
    //             id: 44,
    //             label: 'menuitems.uielements.list.images',
    //             link: ''
    //         },
    //         {
    //             id: 45,
    //             label: 'menuitems.uielements.list.lightbox',
    //             link: ''
    //         }, {
    //             id: 46,
    //             label: 'menuitems.uielements.list.modals',
    //             link: ''
    //         }, {
    //             id: 47,
    //             label: 'menuitems.uielements.list.rangeslider',
    //             link: ''
    //         }, {
    //             id: 48,
    //             label: 'menuitems.uielements.list.sessiontimeout',
    //             link: ''
    //         },
    //         {
    //             id: 49,
    //             label: 'menuitems.uielements.list.progressbar',
    //             link: ''
    //         }, {
    //             id: 50,
    //             label: 'menuitems.uielements.list.sweetalert',
    //             link: ''
    //         }, {
    //             id: 51,
    //             label: 'menuitems.uielements.list.tabs',
    //             link: ''
    //         }, {
    //             id: 52,
    //             label: 'menuitems.uielements.list.typography',
    //             link: ''
    //         }, {
    //             id: 53,
    //             label: 'menuitems.uielements.list.video',
    //             link: ''
    //         }, {
    //             id: 54,
    //             label: 'menuitems.uielements.list.general',
    //             link: ''
    //         }, {
    //             id: 55,
    //             label: 'menuitems.uielements.list.rating',
    //             link: ''
    //         }, {
    //             id: 56,
    //             label: 'menuitems.uielements.list.notifications',
    //             link: ''
    //         }
    //     ]
    // },
    // {
    //     id: 57,
    //     label: 'menuitems.forms.text',
    //     icon: 'ri-eraser-fill',
    //     badge: {
    //         variant: 'danger',
    //         text: 'menuitems.forms.badge'
    //     },
    //     subItems: [
    //         {
    //             id: 58,
    //             label: 'menuitems.forms.list.elements',
    //             link: ''
    //         },
    //         {
    //             id: 59,
    //             label: 'menuitems.forms.list.validation',
    //             link: ''
    //         },
    //         {
    //             id: 60,
    //             label: 'menuitems.forms.list.advanced',
    //             link: ''
    //         },
    //         {
    //             id: 61,
    //             label: 'menuitems.forms.list.editor',
    //             link: ''
    //         },
    //         {
    //             id: 62,
    //             label: 'menuitems.forms.list.fileupload',
    //             link: ''
    //         },
    //         {
    //             id: 63,
    //             label: 'menuitems.forms.list.wizard',
    //             link: ''
    //         },
    //         {
    //             id: 64,
    //             label: 'menuitems.forms.list.mask',
    //             link: '/form/mask'
    //         }
    //     ]
    // },
    // {
    //     id: 65,
    //     label: 'menuitems.tables.text',
    //     icon: 'ri-table-2',
    //     subItems: [
    //         {
    //             id: 66,
    //             label: 'menuitems.tables.list.basic',
    //             link: ''
    //         },
    //         {
    //             id: 67,
    //             label: 'menuitems.tables.list.advanced',
    //             link: ''
    //         },
    //     ]
    // },
    // {
    //     id: 69,
    //     label: 'menuitems.charts.text',
    //     icon: 'ri-bar-chart-line',
    //     subItems: [
    //         {
    //             id: 70,
    //             label: 'menuitems.charts.list.apex',
    //             link: ''
    //         },
    //         {
    //             id: 71,
    //             label: "menuitems.charts.list.chartjs",
    //             link: ''
    //         },
    //         {
    //             id: 72,
    //             label: "menuitems.charts.list.chartist",
    //             link: ''
    //         },
    //         {
    //             id: 73,
    //             label: "menuitems.charts.list.echart",
    //             link: ''
    //         }
    //     ]
    // },
    // {
    //     id: 74,
    //     label: "menuitems.icons.text",
    //     icon: 'ri-brush-line',
    //     subItems: [
    //         {
    //             id: 75,
    //             label: 'menuitems.icons.list.remix',
    //             link: ''
    //         },
    //         {
    //             id: 76,
    //             label: "menuitems.icons.list.materialdesign",
    //             link: ''
    //         },
    //         {
    //             id: 77,
    //             label: "menuitems.icons.list.dripicons",
    //             link: ''
    //         },
    //         {
    //             id: 78,
    //             label: "menuitems.icons.list.fontawesome",
    //             link: ''
    //         }
    //     ]
    // },
    // {
    //     id: 79,
    //     label: "menuitems.maps.text",
    //     icon: 'ri-map-pin-line',
    //     subItems: [
    //         {
    //             id: 80,
    //             label: "menuitems.maps.list.googlemap",
    //             link: ''
    //         },
    //         {
    //             id: 81,
    //             label: 'menuitems.maps.list.leaflet',
    //             link: ''
    //         }
    //     ]
    // }
]