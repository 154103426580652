import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const permissionService = {
  getAll,
  createOrUpdate,
  getById,
  deleteById,
  updateRolePermissions,
  getCurrentUserPermission,
}

function getById(permissionId) {
  const requestOptions = {
    url: '/api/v1/user/permission/' + permissionId,
    method: 'GET',
    headers: authHeader()
  }
  return axiosInstance(requestOptions)
}

function getAll() {
  const requestOptions = {
    url: '/api/v1/user/permission',
    method: 'GET',
    headers: authHeader()
  }
  return axiosInstance(requestOptions)
}


function createOrUpdate(jsonData) {
  let formData = new FormData()
  jsonData['ID'] = jsonData['id']
  for (var key in jsonData) {
    if (jsonData[key] != null)
      formData.append(key, jsonData[key])
  }
  let rMethod = jsonData["id"] != null ? "PUT" : "POST"
  const requestOptions = {
    url: '/api/v1/user/permission',
    method: rMethod,
    data: formData,
    headers: authHeader()
  }

  return axiosInstance(requestOptions)
}


function deleteById(id) {
  const requestOptions = {
    url: `/api/v1/user/permission/` + id,
    method: 'DELETE',
    headers: authHeader()
  }
  return axiosInstance(requestOptions)
}

function updateRolePermissions(jsonData) {
  let formData = new FormData()
  for (var key in jsonData) {
    if (jsonData[key] != null)
      formData.append(key, jsonData[key])
  }
  let rMethod = "PUT"
  const requestOptions = {
    url: '/api/v1/user/permission/role',
    method: rMethod,
    data: formData,
    headers: authHeader()
  }

  return axiosInstance(requestOptions)
}

function getCurrentUserPermission() {
  const requestOptions = {
    url: '/api/v1/user/check_permission',
    method: 'GET',
    headers: authHeader()
  }
  return axiosInstance(requestOptions)
}